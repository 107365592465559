<template>
  <v-data-table
    class="quotation-card-table fill-height last-table-cell-fit-content"
    :headers="headers"
    :items="[...quotationsPendingInvoice, ...quotations]"
    :loading="loadingAll"
    data-cy="quotation-table"
  >
    <template #top>
      <v-toolbar
        flat
        color="white"
      >
        <v-toolbar-title v-t="'invoices.quotations'" />
      </v-toolbar>
      <v-divider />
    </template>
    <template #item.date="{ item }">
      {{ item.date | formatDate }}
    </template>
    <template #item.amount="{ item }">
      <div class="amount-cell-content">
        {{ item.amount || '' }}
      </div>
    </template>
    <template #item.actions="{ item }">
      <div class="fit-content-table-cell">
        <span v-if="item.pending">
          <v-progress-circular
            indeterminate
            color="secondary"
            size="25"
            class="mr-1"
          />

          {{ $t('invoices.pendingInvoice') }}
        </span>
        <template v-else-if="showAcceptButton(item)">
          <material-invoices-and-quotations-pay-modal-window
            v-if="(!isPartnerLoggedIn && !item.invoiceToPartner) || (isPartnerLoggedIn && item.invoiceToPartner)"
            button-name="payOnline"
            :order="item.order"
            :client-id="clientId"
            :invoice-to-partner="item.invoiceToPartner"
          />
          <v-btn
            rounded
            depressed
            color="primary"
            class="my-2"
            @click="handleCreateInvoice(item)"
          >
            {{ $t('invoices.createInvoice') }}
          </v-btn>
        </template>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import {
  ADMIN_ACCESS_LEVEL,
  DOWNLOAD_DOCUMENT_ICON,
  PERMISSIONS_NAME,
  PURCHASE_ORDERS_ACCESS_LEVEL, ROLE_ADMIN, ROLE_NAME
} from '../../../constants/app'

export default {
  props: {
    clientId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      downloadIcon: DOWNLOAD_DOCUMENT_ICON
    }
  },
  computed: {
    ...mapState('invoicesAndQuotations', ['loadingAll']),
    ...mapGetters('invoicesAndQuotations', {
      quotationsGetter: 'quotations',
      quotationsPendingInvoiceGetter: 'quotationsPendingInvoice'
    }),
    ...mapGetters('client', ['clientById']),
    quotations () {
      return this.quotationsGetter(this.clientId)
    },
    quotationsPendingInvoice () {
      return this.quotationsPendingInvoiceGetter(this.clientId)
        ? this.quotationsPendingInvoiceGetter(this.clientId)
        : []
    },
    headers () {
      return [
        { text: this.$i18n.t('invoices.dateIssued'), value: 'date' },
        {
          text: this.$i18n.t('invoices.amountWithoutTax'),
          value: 'amount',
          align: 'end'
        },
        {
          text: this.$i18n.t('actions'),
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ]
    },
    isPartnerLoggedIn () {
      return localStorage.getItem(ROLE_NAME) === ROLE_ADMIN
    }
  },
  methods: {
    ...mapActions('invoicesAndQuotations', ['createInvoice']),
    showAcceptButton (quotation) {
      const paymentPermissions = [ADMIN_ACCESS_LEVEL, PURCHASE_ORDERS_ACCESS_LEVEL]
      const clientPermissions = JSON.parse(localStorage.getItem(PERMISSIONS_NAME))
      const hasRequiredPermission = clientPermissions.some(permission => paymentPermissions.includes(permission))

      return (
        hasRequiredPermission &&
        quotation.order &&
        quotation.order.calculoidData
      )
    },
    async handleCreateInvoice (quotation) {
      await this.createInvoice({ clientId: this.clientId, quotationId: quotation.id })
    }
  }
}
</script>

<style lang="scss">
.quotation-card-table {
  position: relative;
}
</style>
